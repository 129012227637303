import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';

import { Heading6, TextBodySmall } from 'components/TextStyles';
import ContentfulButton from './ContentfulButton';

const Product = ({ title, type, category, description, thumbnail, button }) => {
  return (
    <Wrapper>
      <Image src={thumbnail.file.url} alt={thumbnail.file.description} />
      <Title>
        <Heading6 as="h6" color={colors.humeBlack700}>
          {title}
        </Heading6>
      </Title>
      <Content>
        <TypeWrapper>
          <Type
            color={
              type === 'Model' ? colors.pastelBlue400 : colors.pastelPink400
            }
          >
            {type}
          </Type>
          <Solution>{category}</Solution>
        </TypeWrapper>
        <Text>
          <TextBodySmall as="p">{description}</TextBodySmall>
        </Text>
      </Content>
      {button && (
        <ButtonWrapper>
          <ContentfulButton data={button} />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default Product;

const Wrapper = styled.div`
  background: ${colors.humeTan400};
  border: 1px solid ${colors.humeBlack100};
  display: grid;

  max-width: 100%;
  width: 331px;
  padding: 30px;
  height: 314px;
  border-radius: 10px;
  grid-template-rows: [top] 66px [text] 120px [link] 18px;
  grid-template-columns: [image] 66px [title] 1fr;
  gap: 20px;

  ${mq.tabletP} {
    grid-template-rows: [top] 125px [text] 101px [link] 18px;
    grid-template-columns: [image] 125px [title] 1fr;
    gap: 30px;
    width: 399px;
    height: 363px;
  }

  ${mq.deskL} {
    grid-template-rows: [top] 125px [text] 108px [link] 18px;
    grid-template-columns: [image] 125px [title] 1fr;
    gap: 28px;
    width: 399px;
    height: 368px;
  }

  // ${mq.contained} {
  //   grid-template-rows: [top] 6.51vw [text] 5.63vw [link] 0.94vw;
  //   grid-template-columns: [image] 6.51vw [title] 1fr;
  //   gap: 1.46vw;
  //   width: 20.78vw;
  //   height: 18.91vw;
  // }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  grid-column: image / title;
  grid-row: top / text;
  object-fit: cover;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: title / end;
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  --webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-word;
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  gap: 10px;

  ${mq.contained} {
    height: 1.3vw;
    gap: 0.52vw;
  }
`;

const Type = styled.div`
  width: 100%;
  height: inherit;
  background-color: ${(props) => props.color};
  font-family: 'FraktionMono', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  grid-row: type / text;
  grid-column: image / end;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: fit-content;

  border-radius: 2px;
  padding: 0px 5px;
  font-size: 16px;

  ${mq.contained} {
    font-size: 0.83vw;
    border-radius: 0.1vw;
    padding: 0vw 0.26vw;
  }
`;

const Solution = styled.p`
  font-family: 'FraktionMono', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;

  font-size: 16px;

  ${mq.deskL} {
    font-size: 0.89vw;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-row: link / end;
  grid-column: image / end;
`;

const Content = styled.div`
  grid-row: text / link;
  grid-column: image / end;
  display: flex;
  flex-direction: column;

  gap: 15px;

  ${mq.deskL} {
    gap: 20px;
  }

  ${mq.contained} {
    gap: 1.04vw;
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const STARTING = {
  cx: 6.32,
  cy: 6.32,
  r: 5.32,
};

const CIRCLE_2_END = {
  cx: 25,
  cy: 6.32,
  r: 5.32,
};

const CIRCLE_3_END = {
  cx: 43.68,
  cy: 6.32,
  r: 5.32,
};

const CIRCLE_4_END = {
  cx: 6.32,
  cy: 25,
  r: 5.32,
};

const CIRCLE_5_END = {
  cx: 25,
  cy: 25,
  r: 5.32,
};

const CIRCLE_6_END = {
  cx: 43.68,
  cy: 25,
  r: 5.32,
};

const CIRCLE_7_END = {
  cx: 6.32,
  cy: 43.68,
  r: 5.32,
};

const CIRCLE_8_END = {
  cx: 25,
  cy: 43.68,
  r: 5.32,
};

const CIRCLE_9_END = {
  cx: 43.68,
  cy: 43.68,
  r: 5.32,
};

const ApiCall = ({ trigger, delay }) => {
  const [circle2, setCircle2] = useState(null);
  const [circle3, setCircle3] = useState(null);
  const [circle4, setCircle4] = useState(null);
  const [circle5, setCircle5] = useState(null);
  const [circle6, setCircle6] = useState(null);
  const [circle7, setCircle7] = useState(null);
  const [circle8, setCircle8] = useState(null);
  const [circle9, setCircle9] = useState(null);
  const [tl, setTl] = useState(null);

  useEffect(() => {
    if (
      circle2 &&
      circle3 &&
      circle4 &&
      circle5 &&
      circle6 &&
      circle7 &&
      circle8 &&
      circle9
    ) {
      const tl = gsap.timeline({
        delay,
        paused: true,
      });

      tl.from(
        [
          circle3,
          circle2,
          circle4,
          circle5,
          circle6,
          circle9,
          circle8,
          circle7,
        ],
        {
          duration: 1,
          stagger: 0.1,
          attr: STARTING,
        },
      );

      setTl(tl);

      return () => {
        tl.kill();
      };
    }
  }, [
    circle2,
    circle3,
    circle4,
    circle5,
    circle6,
    circle7,
    circle8,
    circle9,
    setTl,
    delay,
  ]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <Circle {...STARTING} />
      <Circle ref={(ref) => setCircle2(ref)} {...CIRCLE_2_END} />
      <Circle ref={(ref) => setCircle3(ref)} {...CIRCLE_3_END} />
      <Circle ref={(ref) => setCircle4(ref)} {...CIRCLE_4_END} />
      <Circle ref={(ref) => setCircle5(ref)} {...CIRCLE_5_END} />
      <Circle ref={(ref) => setCircle6(ref)} {...CIRCLE_6_END} />
      <Circle ref={(ref) => setCircle7(ref)} {...CIRCLE_7_END} />
      <Circle ref={(ref) => setCircle8(ref)} {...CIRCLE_8_END} />
      <Circle ref={(ref) => setCircle9(ref)} {...CIRCLE_9_END} />
    </Wrapper>
  );
};

export default ApiCall;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
  opacity: 0.2;

  :nth-child(1) {
    opacity: 1;
  }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const CIRCLE_START = {
  cx: 25,
  cy: -6.32,
  r: 5.32,
};

const CIRCLE_1_END = {
  cx: 25,
  cy: 6.32,
  r: 5.32,
};

const CIRCLE_2_END = {
  cx: 25,
  cy: 25,
  r: 5.32,
};

const CIRCLE_3_END = {
  cx: 25,
  cy: 43.68,
  r: 5.32,
};

const LARGE_LINE_START = {
  y1: 0,
  y2: 0,
};

const LINE_1_END = {
  x1: 0.5,
  x2: 0.5,
  y1: 0,
  y2: 50,
};

const LINE_2_END = {
  x1: 49.5,
  x2: 49.5,
  y1: 0,
  y2: 50,
};

const LINE_3_START = {
  x1: 0.5,
  x2: 0.5,
  y1: 7,
  y2: 43,
};

const LINE_3_END = {
  x1: 4.5,
  x2: 4.5,
  y1: 7,
  y2: 43,
};

const LINE_4_START = {
  x1: 49.5,
  x2: 49.5,
  y1: 7,
  y2: 43,
};

const LINE_4_END = {
  x1: 45.5,
  x2: 45.5,
  y1: 7,
  y2: 43,
};

const LINE_5_START = {
  x1: 0.5,
  x2: 0.5,
  y1: 12,
  y2: 38,
};

const LINE_5_END = {
  x1: 8.5,
  x2: 8.5,
  y1: 12,
  y2: 38,
};

const LINE_6_START = {
  x1: 49.5,
  x2: 49.5,
  y1: 12,
  y2: 38,
};

const LINE_6_END = {
  x1: 41.5,
  x2: 41.5,
  y1: 12,
  y2: 38,
};

const Ethics = ({ trigger, delay }) => {
  const [tl, setTl] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay,
      paused: true,
    });

    tl.from(
      '.ethics-large-line',
      {
        duration: 0.5,
        attr: LARGE_LINE_START,
      },
      0,
    );

    tl.fromTo(
      ['.ethics-line-3', '.ethics-line-4', '.ethics-line-5', '.ethics-line-6'],
      {
        display: 'none',
      },
      {
        display: 'unset',
      },
      [0.5],
    );

    tl.from(
      '.ethics-line-3',
      {
        duration: 0.5,
        attr: LINE_3_START,
      },
      0.5,
    );

    tl.from(
      '.ethics-line-4',
      {
        duration: 0.5,
        attr: LINE_4_START,
      },
      0.5,
    );

    tl.from(
      '.ethics-line-5',
      {
        duration: 0.5,
        attr: LINE_5_START,
      },
      0.5,
    );

    tl.from(
      '.ethics-line-6',
      {
        duration: 0.75,
        attr: LINE_6_START,
      },
      0.5,
    );

    tl.from(
      '.ethics-circle',
      {
        duration: 0.75,
        stagger: 0.1,
        attr: CIRCLE_START,
      },
      0.5,
    );

    setTl(tl);

    return () => {
      tl.kill();
    };
  }, [setTl, delay]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <Circle className="ethics-circle" {...CIRCLE_1_END} />
      <Circle className="ethics-circle" {...CIRCLE_2_END} />
      <Circle className="ethics-circle" {...CIRCLE_3_END} />
      <Line className="ethics-large-line" {...LINE_1_END} />
      <Line className="ethics-large-line" {...LINE_2_END} />
      <Line opacity={0.4} className="ethics-line-3" {...LINE_3_END} />
      <Line opacity={0.4} className="ethics-line-4" {...LINE_4_END} />
      <Line opacity={0.1} className="ethics-line-5" {...LINE_5_END} />
      <Line opacity={0.1} className="ethics-line-6" {...LINE_6_END} />
    </Wrapper>
  );
};

export default Ethics;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

const Line = styled.line`
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
  opacity: ${(props) => props.opacity || 1};
`;

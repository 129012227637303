import React, { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';
import { TextCTA, Heading2, TextBodyLarge } from 'components/TextStyles';
import Feature from 'components/Feature';

import API from 'components/_animatedSvgs/Api';
import Ethics from 'components/_animatedSvgs/Ethics';
import Insights from 'components/_animatedSvgs/Insights';
import Interactive from 'components/_animatedSvgs/Interactive';
import StateOfTheArt from 'components/_animatedSvgs/StateOfTheArt';
import Usage from 'components/_animatedSvgs/Usage';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';
import InViewSection from 'components/InViewSection';
import SectionWrapper from './_layouts/SectionWrapper';

const SolutionsFeatures = ({ kicker, title, text, features }) => {
  const featureEls = useMemo(
    () =>
      features.map((item, index) => {
        let Animation;

        switch (item.animation[0]) {
          case 'api':
            Animation = API;
            break;

          case 'ethics':
            Animation = Ethics;
            break;

          case 'insights':
            Animation = Insights;
            break;

          case 'interactive':
            Animation = Interactive;
            break;

          case 'state of the art':
            Animation = StateOfTheArt;
            break;

          case 'usage':
            Animation = Usage;
            break;

          default:
            Animation = API;
            break;
        }

        const delay = index * 0.1 + 0.6;

        return (
          <AnimateFadeIn delay={delay} key={item.title + index}>
            <InViewSection>
              <Feature
                Animation={Animation}
                title={item.title}
                description={item.description}
              />
            </InViewSection>
          </AnimateFadeIn>
        );
      }),
    [features],
  );

  return (
    <SectionWrapper data-filter-section="api" dark={true}>
      <Kicker>
        <TextCTA color={colors.humeTan600}>{kicker}</TextCTA>
      </Kicker>
      <Title>
        <Heading2 as="h2" color={colors.humeTan400}>
          {title}
        </Heading2>
      </Title>
      <Text>
        <TextBodyLarge color={colors.humeTan400}>{text}</TextBodyLarge>
      </Text>
      <Content>{featureEls}</Content>
    </SectionWrapper>
  );
};

export default SolutionsFeatures;

const Kicker = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;

  ${mq.contained} {
    margin-bottom: 1.04vw;
  }
`;

const Title = styled.div`
  width: 100%;

  margin-bottom: 20px;

  ${mq.desk} {
    width: 694px;
  }

  ${mq.contained} {
    width: 36.15vw;
    margin-bottom: 1.04vw;
  }
`;

const Text = styled.div`
  width: 100%;

  margin-bottom: 60px;

  ${mq.tabletP} {
    margin-bottom: 80px;
  }

  ${mq.desk} {
    width: 694px;
    margin-bottom: 100px;
  }

  ${mq.contained} {
    width: 36.15vw;
    margin-bottom: 5.21vw;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 80px;

  ${mq.tabletP} {
    gap: 118px;
  }

  ${mq.desk} {
    gap: 7vw 13.31vw;
  }

  ${mq.deskL} {
    gap: 5vw 9vw;
  }

  ${mq.contained} {
    gap: 5vw 9.22vw;
  }
`;

import React from 'react';
import styled from 'styled-components';

import { mq } from 'styles/vars/media-queries.style';
import { colors } from 'styles/vars/colors.style';

import { Heading2, TextBodyLarge, TextCTA } from 'components/TextStyles';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';
import HomeProductCarousel from 'components/HomeProductCarousel';

const HomeProduct = ({
  title,
  kickerText,
  text,
  rowOneLabel,
  rowTwoLabel,
  rowOneItems,
  rowTwoItems,
}) => {
  return (
    <Wrapper>
      <Heading>
        <AnimateFadeIn delay={0.2}>
          <Kicker color={colors.humeTan700}>{kickerText}</Kicker>
        </AnimateFadeIn>
        <AnimateFadeIn delay={0.4}>
          <Heading2 as="h2">{title}</Heading2>
        </AnimateFadeIn>
        <AnimateFadeIn delay={0.6}>
          <Text>
            <TextBodyLarge>{text}</TextBodyLarge>
          </Text>
        </AnimateFadeIn>
      </Heading>
      <Content>
        <HomeProductCarousel
          link={'/products'}
          filter={rowOneLabel}
          title={rowOneLabel}
          products={rowOneItems}
        />
        <HomeProductCarousel
          link={'/products'}
          filter={rowTwoLabel}
          title={rowTwoLabel}
          products={rowTwoItems}
        />
      </Content>
    </Wrapper>
  );
};

export default HomeProduct;

const Wrapper = styled.div`
  overflow: hidden;
  padding: 10rem 2.2rem 15rem;

  ${mq.tabletP} {
    padding: 15rem 10rem;
  }

  ${mq.desk} {
    padding: 20rem 13.3rem;
  }
`;

const Heading = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;

  ${mq.tabletL} {
    width: 822px;
  }

  ${mq.desk} {
    width: 60.65vw;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${mq.tabletP} {
    gap: 40px;
  }
`;

const Kicker = styled(TextCTA)`
  margin-bottom: 10px;

  ${mq.desk} {
    margin-bottom: 20px;
  }
`;

const Text = styled.div`
  width: 100%;

  margin-bottom: 60px;

  ${mq.desk} {
  }

  ${mq.contained} {
    width: 60%;
    margin-bottom: 3.13vw;
  }
`;

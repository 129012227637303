import { useContext } from 'react';
import { ScreenContext } from 'components/Providers';

export const useMedia = (c, dl, d, tl, tp, ml, m, t) => {
  const screen = useContext(ScreenContext);

  if (screen.contained) {
    return c;
  } else if (screen.deskL) {
    return dl;
  } else if (screen.desk) {
    return d;
  } else if (screen.tabletL) {
    return tl;
  } else if (screen.tabletP) {
    return tp;
  } else if (screen.mobileL) {
    return ml;
  } else if (screen.mobile) {
    return m;
  } else if (screen.tiny) {
    return t;
  }
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const CIRCLE_1_START = {
  cx: 25,
  cy: 25,
  r: 18,
};

const CIRCLE_2_START = {
  cx: 25,
  cy: 25,
  r: 4,
};

const CIRCLE_1_END = {
  cx: 25,
  cy: 25,
  r: 24.5,
};

const CIRCLE_2_END = {
  cx: 25,
  cy: 25,
  r: 9,
};

const RECT_END = {
  x: 7.32,
  y: 7.32,
  width: 35,
  height: 35,
};

const StateOfTheArt = ({ trigger, delay }) => {
  const [tl, setTl] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay,
      paused: true,
    });

    tl.fromTo(
      '.state-circle-1',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        attr: CIRCLE_1_END,
      },
      0,
    );

    tl.fromTo(
      '.state-circle-2',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        attr: CIRCLE_2_END,
      },
      0.2,
    );

    tl.fromTo(
      '.state-rect',
      {
        scale: 0.5,
        opacity: 0,
        rotate: 0,
        svgOrigin: '25 25',
      },
      {
        duration: 0.5,
        opacity: 1,
        scale: 1,
        rotate: -45,
      },
      0.2,
    );

    setTl(tl);

    return () => {
      tl.kill();
    };
  }, [setTl, delay]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <Circle className="state-circle-1" {...CIRCLE_1_START} />
      <Circle className="state-circle-2" {...CIRCLE_2_START} />
      <Rect className="state-rect" {...RECT_END} />
    </Wrapper>
  );
};

export default StateOfTheArt;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

const Rect = styled.rect`
  stroke-width: 1px;
  fill: none;
  stroke: ${colors.neutralWhite};
`;

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { mq } from 'styles/vars/media-queries.style';
import { clamp } from 'styles/utils/conversion.style';

import { isBrowser, vwToPx } from 'utils/utils';

import { useMedia } from 'hooks/useMedia';

import Product from 'components/Product';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';
import Button from 'components/Button';
import {
  buttonVariants,
  ButtonIconWrapper,
} from 'components/Button/index.style';
import IconArrowHeadRight from 'components/_svgs/IconArrowheadRight';

import { colors } from 'styles/vars/colors.style';
import { breakpoints } from '../styles/vars/breakpoints.style';

const ProductCarousel = ({ title, products, link, filter }) => {
  const [contentEl, setContentEl] = useState(null);
  const [visibleBoxes, setVisibleBoxes] = useState(products.length);
  const [showArrows, setShowArrows] = useState(false);
  const parentPadding = useMedia(vwToPx(6.93), 133, 133, 100, 100, 22, 22, 22);
  const [translateIndex, setTranslateIndex] = useState(0);
  const [navWidth, setNavWidth] = useState(0);

  // product preview width + gap
  const productWidth = useMedia(
    vwToPx(20.78),
    399,
    399,
    399,
    399,
    331,
    331,
    331,
  );

  const gap = useMedia(vwToPx(1.04), 20, 20, 25, 25, 15, 15, 15);

  const decreaseIndex = () => {
    if (translateIndex === 0) {
      return;
    } else {
      setTranslateIndex(translateIndex - 1);
    }
  };

  const increaseIndex = () => {
    if (translateIndex >= products.length - visibleBoxes) {
      return;
    } else {
      setTranslateIndex(translateIndex + 1);
    }
  };

  const calcNavWidth = useCallback(() => {
    if (contentEl) {
      const contentWidth = contentEl.getBoundingClientRect().width;
      const parentWidth = window.innerWidth - parentPadding;

      if (contentWidth > parentWidth) {
        const visibleBoxes =
          Math.floor(parentWidth / (productWidth + gap)) || 1;
        setVisibleBoxes(visibleBoxes);
        const width = visibleBoxes * (productWidth + gap) - gap;
        setNavWidth(width);
        setShowArrows(true);
      } else {
        setShowArrows(false);
      }
    }
  }, [
    contentEl,
    parentPadding,
    setNavWidth,
    setShowArrows,
    setVisibleBoxes,
    productWidth,
    gap,
  ]);

  useEffect(() => {
    if (isBrowser()) {
      calcNavWidth();

      window.addEventListener('resize', calcNavWidth);

      return () => {
        window.removeEventListener('resize', calcNavWidth);
      };
    }
  }, [calcNavWidth]);

  useEffect(() => {
    const translate = (index) => {
      gsap.to(contentEl, {
        x: -index * (productWidth + gap),
      });
    };

    translate(translateIndex);
  }, [translateIndex, contentEl, productWidth, gap]);

  const productEls = useMemo(
    () =>
      products.map((item, index) => {
        return (
          <AnimateFadeIn key={item.title + index} delay={index * 0.3}>
            <Product
              title={item.title}
              type={item.solutionType}
              category={item.category}
              description={item.metaDescription.metaDescription}
              slug={item.slug}
              thumbnail={item.thumbnail}
              button={item.previewButton}
            />
          </AnimateFadeIn>
        );
      }),
    [products],
  );

  return (
    <Wrapper>
      <Top width={navWidth}>
        <Row>
          <Title>{title}</Title>
        </Row>
        {showArrows && (
          <Buttons>
            <ButtonWrapper disabled={translateIndex === 0} $rotate>
              <Button
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
                ariaLabel={`Previous`}
                disabled={translateIndex === 0}
                onClick={decreaseIndex}
              ></Button>
            </ButtonWrapper>
            <ButtonWrapper
              disabled={translateIndex >= products.length - visibleBoxes}
            >
              <Button
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
                ariaLabel={`Next`}
                disabled={translateIndex >= products.length - visibleBoxes}
                onClick={increaseIndex}
              ></Button>
            </ButtonWrapper>
          </Buttons>
        )}
      </Top>
      <GradientTwo />
      <Content ref={(ref) => setContentEl(ref)}>{productEls}</Content>

      <Gradient />
    </Wrapper>
  );
};

export default ProductCarousel;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;
  margin-bottom: 10px;
  width: 100%;

  ${mq.deskL} {
    width: 86.15vw;
  }
`;

const Row = styled.div`
  display: flex;

  gap: 25px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 15px;

  ${mq.tabletP} {
    gap: 25px;
  }

  ${mq.desk} {
    gap: 20px;
  }

  ${mq.contained} {
    gap: 1.04vw;
  }
`;

const Title = styled.div`
  height: 100%;
  min-height: 32px;
  background: ${colors.humeBlack700};
  font-family: 'FraktionMono', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  grid-row: type / text;
  grid-column: image / end;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.humeTan400};
  border-radius: 2px;
  padding: 0px 10px;
  margin-right: 20px;

  ${mq.mobile} {
    padding: 6px 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: unset;

  gap: 20px;
`;

const ButtonWrapper = styled.div`
  text-align: right;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transform: ${(props) => (props.$rotate ? 'scaleX(-1)' : 'unset')};

  ${mq.desk} {
    display: block;
  }

  button {
    border-radius: 50%;
    border: 0.1rem solid ${colors.humeBlack700};
    height: 5.6rem;
    min-width: 5.6rem;
    padding: 0;
    width: 5.6rem;
    margin-bottom: unset;

    span {
      font-size: 0;
      margin-left: 0;
      padding: 0;
    }
  }

  ${ButtonIconWrapper} {
    margin-top: 0;
  }
`;

const Gradient = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  right: 0;
  background: linear-gradient(
    270deg,
    ${colors.light} 4.67%,
    rgba(255, 244, 232, 0) 100%
  );
  top: 80px;

  transform: translateX(2.2rem);

  ${mq.mobile} {
    display: flex;
    height: 314px;
    ${clamp('width', 0, 250, breakpoints.mobile, breakpoints.tabletP)}
  }

  ${mq.tabletP} {
    transform: translateX(10rem);
    width: 150px;
    height: 363px;
  }

  ${mq.desk} {
    transform: translateX(13.3rem);
  }
`;

const GradientTwo = styled(Gradient)`
  transform: translateX(-100%) rotate(180deg);
  right: unset;
  left: 0;
  transform: translateX(-14.65vw) rotate(180deg);

  transform: translateX(-150px) rotate(180deg);
`;

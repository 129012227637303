import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const CIRCLE_START = {
  cx: 25,
  cy: 11,
  r: 10.64,
};

const CIRCLE_END = {
  cx: 25,
  cy: 44.5,
  r: 5.32,
};

const LINE_START = {
  x1: 0,
  x2: 0,
  y1: 30,
  y2: 30,
};

const LINE_END = {
  x1: 0,
  x2: 50,
  y1: 30,
  y2: 30,
};

const Insights = ({ trigger, delay }) => {
  const [tl, setTl] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay,
      paused: true,
    });

    tl.to(
      '.insights-line',
      {
        duration: 0.5,
        attr: LINE_END,
        ease: 'none',
      },
      0,
    );

    tl.fromTo(
      ['.insights-circle', '.insights-circle-1'],
      {
        opacity: 0,
      },
      {
        duration: 0.5,
        opacity: 0.5,
      },
      0.5,
    );

    tl.from(
      '.insights-circle',
      {
        attr: CIRCLE_START,
        duration: 0.5,
        stagger: 0.5,
      },
      0.7,
    );

    setTl(tl);

    return () => {
      tl.kill();
    };
  }, [setTl, delay]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <Circle className="insights-circle-1" {...CIRCLE_START} />
      <Circle className="insights-circle" {...CIRCLE_END} />
      <Circle className="insights-circle" {...CIRCLE_END} />
      <Circle className="insights-circle" {...CIRCLE_END} />
      <Line className="insights-line" {...LINE_START} />
    </Wrapper>
  );
};

export default Insights;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
  opacity: 0.5;
`;

const Line = styled.line`
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const COL_1_START = {
  cx: 6.32,
  cy: 43.68,
  r: 5.32,
};

const COL_2_START = {
  cx: 25,
  cy: 43.68,
  r: 5.32,
};

const COL_3_START = {
  cx: 43.68,
  cy: 43.68,
  r: 5.32,
};

const CIRCLE_1_END = {
  cx: 25,
  cy: 25,
  r: 5.32,
};

const CIRCLE_2_END = {
  cx: 25,
  cy: 6.32,
  r: 5.32,
};

const CIRCLE_3_END = {
  cx: 43.68,
  cy: 25,
  r: 5.32,
};

const Usage = ({ trigger, delay }) => {
  const [tl, setTl] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay,
      paused: true,
    });

    tl.fromTo(
      ['.usage-column-1', '.usage-column-2', '.usage-column-3'],
      {
        opacity: 0,
      },
      {
        duration: 0.25,
        stagger: 0.25,
        opacity: 1,
      },
      0,
    );

    tl.to(
      '.usage-circle-1',
      {
        duration: 0.5,
        attr: CIRCLE_1_END,
      },
      0.75,
    );

    tl.to(
      '.usage-circle-2',
      {
        duration: 0.5,
        attr: CIRCLE_2_END,
      },
      1,
    );

    tl.to(
      '.usage-circle-3',
      {
        duration: 0.5,
        attr: CIRCLE_3_END,
      },
      1,
    );

    setTl(tl);

    return () => {
      tl.kill();
    };
  }, [setTl, delay]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <g className="usage-column-1">
        <Circle {...COL_1_START} />
      </g>
      <g className="usage-column-2">
        <Circle {...COL_2_START} />
        <Circle className="usage-circle-1" {...COL_2_START} />
        <Circle className="usage-circle-2" {...COL_2_START} />
      </g>
      <g className="usage-column-3">
        <Circle className="usage-circle-3" {...COL_3_START} />
        <Circle {...COL_3_START} />
      </g>
    </Wrapper>
  );
};

export default Usage;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  fill: none;
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import { colors } from 'styles/vars/colors.style';

const CIRCLE_1 = {
  cx: 25,
  cy: 25,
  r: 5,
};

const CIRCLE_2 = {
  cx: 36,
  cy: 6,
  r: 5,
};

const CIRCLE_3 = {
  cx: 44,
  cy: 25,
  r: 5,
};

const CIRCLE_4 = {
  cx: 36,
  cy: 44,
  r: 5,
};

const CIRCLE_5 = {
  cx: 14,
  cy: 44,
  r: 5,
};

const CIRCLE_6 = {
  cx: 6,
  cy: 25,
  r: 5,
};

const CIRCLE_7 = {
  cx: 14,
  cy: 6,
  r: 5,
};

const LINE_1 = {
  x1: 25,
  x2: 36,
  y1: 25,
  y2: 6,
};

const LINE_2 = {
  x1: 25,
  x2: 44,
  y1: 25,
  y2: 25,
};

const LINE_3 = {
  x1: 25,
  x2: 36,
  y1: 25,
  y2: 44,
};

const LINE_4 = {
  x1: 25,
  x2: 14,
  y1: 25,
  y2: 44,
};

const LINE_5 = {
  x1: 25,
  x2: 6,
  y1: 25,
  y2: 25,
};

const LINE_6 = {
  x1: 25,
  x2: 14,
  y1: 25,
  y2: 6,
};

const Interactive = ({ trigger, delay }) => {
  const [tl, setTl] = useState(null);

  useEffect(() => {
    const tl = gsap.timeline({
      delay,
      paused: true,
    });

    tl.fromTo(
      '.interactive-line',
      {
        opacity: 0,
      },
      {
        duration: 0.25,
        stagger: 0.25,
        opacity: 1,
      },
      0,
    );

    tl.fromTo(
      '.interactive-circle',
      {
        opacity: 0,
      },
      {
        duration: 0.25,
        stagger: 0.25,
        opacity: 1,
      },
      0.25,
    );

    setTl(tl);

    return () => {
      tl.kill();
    };
  }, [setTl, delay]);

  useEffect(() => {
    if (trigger && tl) {
      tl.play();
    }
  }, [trigger, tl]);

  return (
    <Wrapper viewBox="0 0 50 50">
      <Line className="interactive-line" {...LINE_1} />
      <Line className="interactive-line" {...LINE_2} />
      <Line className="interactive-line" {...LINE_3} />
      <Line className="interactive-line" {...LINE_4} />
      <Line className="interactive-line" {...LINE_5} />
      <Line className="interactive-line" {...LINE_6} />
      <Circle {...CIRCLE_1} />
      <Circle className="interactive-circle" {...CIRCLE_2} />
      <Circle className="interactive-circle" {...CIRCLE_3} />
      <Circle className="interactive-circle" {...CIRCLE_4} />
      <Circle className="interactive-circle" {...CIRCLE_5} />
      <Circle className="interactive-circle" {...CIRCLE_6} />
      <Circle className="interactive-circle" {...CIRCLE_7} />
    </Wrapper>
  );
};

export default Interactive;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  fill: ${colors.humeBlack700};
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

const Line = styled.line`
  stroke: ${colors.neutralWhite};
  stroke-width: 1px;
`;

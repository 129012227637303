import React from 'react';
import Button from 'components/Button';
import PageTransitionLink from 'components/PageTransitionLink';
import { buttonVariants, buttonThemes } from './Button/index.style';
import IconArrowheadRight from './_svgs/IconArrowheadRight';

const ContentfulButton = ({ data, noArrow }) => {
  if (data.internalLink) {
    return (
      <PageTransitionLink to={data.url}>
        <Button
          iconRight={!noArrow && <IconArrowheadRight />}
          variant={buttonVariants[data.variant]}
          theme={buttonThemes[data.theme]}
          newTab={data.newTab}
        >
          {data.text}
        </Button>
      </PageTransitionLink>
    );
  } else {
    return (
      <Button
        as="a"
        href={data.url}
        iconRight={!noArrow && <IconArrowheadRight />}
        variant={buttonVariants[data.variant]}
        theme={buttonThemes[data.theme]}
        newTab={data.newTab}
      >
        {data.text}
      </Button>
    );
  }
};

export default ContentfulButton;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { clamp } from 'styles/utils/conversion.style';
import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';

const SectionWrapper = ({ children, dark, overflow = false }) => {
  return (
    <Wrapper dark={dark}>
      <Main $overflow={overflow}>{children}</Main>
    </Wrapper>
  );
};

export default SectionWrapper;

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  overflow: PropTypes.bool,
};

const Wrapper = styled.div`
  background: ${(props) =>
    props.dark ? colors.humeBlack700 : colors.humeTan400};
  min-height: 100vh;
  padding: 6rem 0;
  transform: translateY(-1%);

  ${mq.tabletP} {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
    ${clamp('padding-left', 65, 125)}
    ${clamp('padding-right', 65, 125)}
  }
`;

const Main = styled.div`
  margin: 0 auto;

  ${(props) =>
    !props.$overflow &&
    `
  ${clamp('padding-left', 22, 50)};
  ${clamp('padding-right', 22, 50)};
  `}

  ${mq.desk} {
    padding: 0;
  }
`;

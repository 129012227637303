import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';

import { Heading5, TextBodyLarge } from 'components/TextStyles';

const Feature = ({ Animation, title, description, inView, delay }) => {
  return (
    <Wrapper>
      {Animation && (
        <AnimationWrapper>
          <Animation trigger={inView} delay={delay} />
        </AnimationWrapper>
      )}
      <Title>
        <Heading5 color={colors.humeTan400}>{title}</Heading5>
      </Title>
      <Text>
        <TextBodyLarge color={colors.humeTan400}>{description}</TextBodyLarge>
      </Text>
    </Wrapper>
  );
};

export default Feature;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mq.mobile} {
    width: 350px;
  }

  ${mq.desk} {
    width: 16.48vw;
  }

  ${mq.deskL} {
    width: 20.23vw;
  }

  ${mq.contained} {
    width: 18.23vw;
  }
`;

const AnimationWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;

  ${mq.contained} {
    width: 2.6vw;
    height: 2.6vw;
    margin-bottom: 1.04vw;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${mq.contained} {
    margin-bottom: 1.04vw;
  }
`;

const Text = styled.div`
  font-weight: 400;
`;
